.skills{
  .training_skill {
    display: flex;
    .td{
      flex: 1 1 0;
    }
    form{
      flex: 1 1 0;
      #form {
        div{
          display: flex;
          label{
            width: fit-content;
          }
        }
        background-color: #8c8c8c;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}
.marginClear{
  margin: 0px;
}
