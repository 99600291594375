/**
 *  This file gives pointers towards what needs to be fixed in development. It
 *  needs to be removed once the dev is done.
 */

[href^="/v1/?"]::after {
  color: red;
  content: " (V1)";
  font-size: 80%;
}
[href^="http://dg-server.com"]::after {
  color: red;
  content: " (DEAD)";
  font-size: 80%;
}
