label {
  flex: 0 0 auto;
}

input {
  flex: 0 1 auto;
}

form#filter label.required:after {
  content: "";
}

form#filter {
  label {
    text-align: right;
  }

  .form-control.number-range-min, .form-control.number-range-max {
    width: auto;
  }
}

.checkbox input[type="checkbox"] {
  margin-left: 0;
  margin-top: 0;
}

.vich-file {
  display: flex;
  align-items: center;

  &.asUploadFile{
    color: green;
  }
  &.isDelete{
    color: red;
  }
}

.vich-image {
  display: flex;
  align-items: center;
}

.entity-choices {
  width: 100%;

  option {
    height: 18px;
    padding-top: 4px;
  }
  option:first-child {
    display: none;
  }
}

.enquiry-wizard {
  textarea{
    resize: vertical;
  }
}

.radio-btn {
  input[type=radio] {
    appearance: none;
    margin: 0;

    +label {
      font-weight: normal;
      border: 1px solid #4f4f5d;
      padding: .25em .5em;
      background: white;
      border-radius: .25em;
      cursor: pointer;
    }

    &:checked+label {
      color: #000;
      background: #1b9448;
    }
  }
}
