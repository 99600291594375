@import "_variables.scss";

.ribbon {
  border-bottom: 1px solid $light-text;
  padding: 0;
  font-size: .8em;
  color: $light-text;
  &>ul {
    display: inline-flex;
    list-style-type: none;
    padding: 0;
    &>li {
      text-align: center;
      padding: 0 1ch;

      & svg {
        height: 4em;
      }
    }
  }
  button {
    border: 1px solid #858d93;
    padding: 0;
    background-color: transparent;
    width: 30px;
  }

  a {
    color: $light-text;
  }

  .ribbon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    position: relative;
    > * {
      transition: 0.1s;
    }
    &:hover  {
      color: black;
      > .bi {
        transform: scale(105%);
      }
    }
  }

  > ul {
    margin: -1px 0;
  }

  .dropdown {
    display: flex;
    > button {
      margin-left: 5px;
      border-top: none;
    }
  }

  .bi {
    display: inline-block;
    font-size: 3em;
    color: black;

    &.add {
      font-size: 1.8em;
      position: absolute;
      bottom: 0.7em;
      right: 1em;
      background: #e0e0e0;
      border-radius: 50%;
      line-height: 0.7;
    }
  }
}
