.formModal{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1{
    color: white;
    font-size: 2em;
    padding-top: 50px;
  }

  .success{
    background-color: white;
    border: green 2px solid;
    border-radius: 8px;
    color: green;
    padding: 4px;
  }

  .cancelIcon{
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-size: 44px;
  }

  .cancelIcon:hover{
    cursor: pointer;
  }

  form {
    position: relative;
    padding: 50px 0 100px 0;
    width: 70vw;

    &>div>div:nth-last-of-type(1)>div:nth-last-of-type(1){
      display: flex;
      justify-content: space-around;
    }
  }
  
  .btn-form {
    display: inline-block;
    padding: 1em;
    width: auto;
  }
}
