@import 'partials/_variables.scss';
@import "~bootstrap/dist/css/bootstrap";

html {
  font-family: $font-family-base;
}

body {
  background: url("../images/logo.jpg") top 50px left no-repeat $body-color;
  font-family: $font-family-base;

  &.dev {
    border: .25em dashed orange;
  }
}

h1 {
  font-size: 1.75em;
  color: $light-text;
}

table {
  width: 100%;
  max-width: 100%;
}

a:link[target=_blank] {
  padding-right: 1em;
  background: url("../images/external_link.svg") no-repeat center right;
}

.form-control {
  border-radius: 0;
  box-shadow: none;
}

textarea.form-control {
  min-height: 9.625em;
}

output {
  display: inline-block;
}

.body {
  display: flex;
}

.main {
  order: 2;
  flex: 1 0 90%;
}

.side-panel {
  order: 1;
  flex: 0 0 10%;
}

.layout-fluid {
  .main {
    flex: 1 0 auto;
  }

  .side-panel {
    flex: 0 0 11em;
  }
}

.block-container {
  background-color: $aside-layout-color;
  padding: 1em;
  margin: 0 auto;

  &.search-container {
    margin-bottom: 1.5em;
  }

  &.dashboard-container {
    background-color: $main-layout-color;

    col.highlight {
      background-color: #ffd2d2;
    }

    table {
      background-color: $aside-layout-color;
      text-align: center;

      th, td {
        border: 1px solid #333;

        &.trimester {
          background-color: $aside-layout-color;
          color: $light-text;
        }
      }
    }

  }

  &.entities-container {
    background-color: $main-layout-color;
    .field-name {
      font-weight: bold;
    }

    tr, .even, .form-group {
      background-color: $evenRow;

      &:hover {
        background-color: lighten($evenRow, 3%);
      }

      input {
        background-color: #fff;

        &[disabled] {
          background-color: #eee;
        }
      }

      &.form-group:nth-of-type(odd) {
        background-color: $oddRow;

        &:hover {
          background-color: lighten($oddRow, 3%);
        }

        input {
          background-color: lighten($oddRow, 5%);

          &[disabled] {
            background-color: #eee;
          }
        }
      }
    }

    .odd {
      background-color: $oddRow;

      &:hover {
        background-color: lighten($oddRow, 3%);
      }

      input {
        background-color: lighten($oddRow, 5%);
      }
    }

    .form-group {
      margin-bottom: 0;
      border-top: 1px solid $light-text;

      .btn-suppr {
        float: right;
        margin: 20px;
        font-weight: bold;

        &:hover {
          background-color: red;
          color: white;
        }
      }
    }

    .form-group .form-group {
      margin-left: 0;
    }

    td {
      border-top: 1px solid #333;

      &.numeric {
        text-align: right;
      }
    }
  }

  &.entity_fields {
    dl {
      display: flex;
      flex-flow: row wrap;
    }

    dt {
      flex: 0 1 20%;
      text-align: right;

      &:nth-of-type(odd), &:nth-of-type(odd) + dd {
        background-color: $oddRow;
      }
    }

    dd {
      flex: 0 1 80%;
      overflow-wrap: anywhere;

      &+dd {
       margin-left: 20%;
      }

      &.heading {
        font-size: 1.5em;
        font-weight: bold;
      }
    }

    dd, dt {
      padding: .5rem 1rem;
      background-color: $evenRow;
      border-top: 1px solid $light-text;
    }
  }
}

.block-container-sep {
  background-color: $main-layout-color;
  height: 1em;
}

/* CSS for the title */
.title {
  background-color: #616161;
  padding: .5rem 1rem;
  color: #EEEEEE;
  text-align: center;

  > h1 {
    margin: .5em 0;
    color: #EEEEEE;
  }

  a {
    color: lighten($link-color, 50%)
  }
}

.button {
  font-size: 1.2em;
  border-radius: 0;
  border-color: transparent;
  background-color: $commit-color;
}

/* */
table.records-list {
  td {
    border-bottom: 1px solid #666666;

    &[role=navigation] {
      padding: 0;
    }
  }
}

/* Total fields in tables */
tfoot.total-field {
  .total-label {
    text-align: center;
  }

  tr {
    font-weight: bold;
  }
}

/* Registration list in TrainingSession page */
table.secondary {
  width: 100%;

  tr {
    td {
      border-top: 1px solid $light-text;
    }

    &:nth-of-type(even) {
      background-color: $evenRow;
    }

    &:nth-of-type(odd) {
      background-color: $oddRow;
    }
    &.highlighted {
      background-color: #AFA;
    }
  }
}

/* buttons "show" and "edit" of each entity */
.quick-actions {
  list-style-type: none;
  display: flex;
  gap: .5em;
  padding: 0;
  margin: 0;

  & > li {
    text-align: center;
  }

  a, button {
    display: block;
    padding: .5em 1em;
    background-color: $link-color;
    color: $aside-layout-color;
    border: 0 none transparent;

    &.toggle-overview {
      cursor: pointer;
    }

    &:active {
      box-shadow: 0 .5em 1em #000;
    }

    &:hover {
      background-color: lighten($link-color, 10%);
      color: lighten($aside-layout-color, 10%);
    }
  }
}

.dashboard__header {
  text-align: center;
}

.dashboard__column--current {
  background: #fcc;
}

.input-group {
  z-index: 1;
}

/*Add a red asterisk to the required fields */
label.required:after {
  content: " *";
  color: red;
}

/* dropdown fix */
.dropdown-menu {
  max-height: 60vh;
  overflow-y: auto;
}

.dropdown-toggle:hover {
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1608px) {
  .nav > li > a {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.add_item_link {
  margin: 1rem 0;
}

.user-select-all {
  user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  -webkit-user-select: all;
}
.warning {
  font-weight: bolder;
  color: darkorange;
}
.success {
  color: darkgreen;
}

.office-worked {
  background-color: #99ffcc;
}

.not-worked {
  background-color: #ccc;
}

.home-worked {
  background-color: #99ccff;
}

.half-worked {
  background-color: orange;
}

#feedbacks_center_chart {
  height: 35.3rem;
}

@import 'partials/_shortcuts_sidebar';
@import 'partials/_local_actions';
@import 'partials/ribbon';
@import 'partials/taskGraph';
@import 'partials/fieldSearch';
@import 'partials/organizationChart';
@import "partials/_forms";
@import 'partials/_tree';
@import 'partials/_funding_widget';
@import 'partials/_extra_widget';
@import "partials/overview";
