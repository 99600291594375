.overview {
  position: fixed;
  top: 1em;
  bottom: 1em;
  right: 1em;
  width: 20%;
  display: none;
  background-color: #EEEEEE;
  padding: .5rem 1rem;
  color: #616161;
  box-shadow: 0 0 1em black;
  z-index: 1;

  :hover:first-child ~ & {
    display: block;
  }
}
