@import 'partials/_variables.scss';

$entity-states: (
  "audit": (
    "1-asked": #DDF,
    "2-inprogress": #FFA,
    "3-done": #BFB,
  ),
  "remotequalification": (
    "asked" : #DDF,
    "inprogress": #FFA,
    "done": #BFB,
  ),
  "certification": (
    "not_dated": #ADF,
    "forthcoming": #7FF,
    "available": #AFA,
    "vanished": #FAA,
  ),
  "company": (
    "canvassing_refused": #FAA,
    "pending": #FDA,
    "client": #AFA,
    "cancelled": #CCC,
  ),
  "enquiry": (
    "newstep1": #AAA,
    "newstep2": #AAA,
    "newstep3": #AAA,
    "inprogress": #AAF,
    "processed": #7FF,
    "success": #AFA,
    "failure": #FAA,
    "cancelled": #F77,
  ),
  "estimate": (
    "2-sent": #FDA,
    "4-signed": #ff9933,
    "5-returned": #AFA,
    "7-later": #AFE,
    "9-cancelled": #BBB,
  ),
  "factoringagreement": (
    "asked": #FDA,
    "agreed": #AFA,
    "refused": #CEC,
  ),
  "freelance": (
    "needs_validation": #AAF,
    "valid": #AFF,
    "confirmed": #CEC,
    "needs_retaining": #AFA,
    "cancelled": #F93,
    "blacklisted": #F77,
  ),
  "invoice": (
    "waiting": #DDF,
    "paid": #BFB,
    "late": #FDA,
    "chased": #FAA,
  ),
  "issue": (
    "unconfirmed": #AAF,
    "new": #FBA,
    "reopened": #FAA,
    "waiting": #FCF,
    "assigned": #FDA,
    "deployable": #DEA,
    "resolved": #AFA,
    "duplicate": #CDB,
    "cancelled": #CEC,
  ),
  "sessionoption": (
    "in_progress":#FDA,
    "transformed":#AFA,
    "cancelled": #FBA,
  ),
  "jobapplication": (
    "1-received": #FAA,
    "3-processing": #FDB,
    "7-tested": #FFB,
    "9-processed": #BFB,
  ),
  "joboffer": (
    "draft": #DDF,
    "published": #BFB,
    "not_published": #FFA,
    "position_filled": #FAA,
    "archived": #CCC,
  ),
  "leaveperiod": (
    "asked": #AAF,
    "rejected": #FAA,
    "committed": #AFA,
  ),
  "letter": (
    "1-preparing": #AAF,
    "2-prepared": #FDA,
    "3-sent": #AFA,
    "4-followed": #AEE,
    "9-cancelled": #BBB,
  ),
  "mailtemplate": (
    "draft": #DDF,
    "in_use": #BFB,
    "deprecated": #FCC,
  ),
  "monthlyassessment": (
    "1-opened": #DDDDFF,
    "2-filled": #FFFFAA,
    "3-used": #FCC,
    "4-closed": #EDD,
  ),
  "preparationcall": (
    "opened": #DDF,
    "inProgress": #FFA,
    "closed": #BFB,
  ),
  "product": (
    "1-writing": #DDF,
    "2-waiting": #FFA,
    "4-cancelled": #FCC,
  ),
  "purchase": (
      "notsent": #FAA,
      "sent": #FDA,
      "convocationsent": #FFA,
      "invoicesent": #AFA,
      "questionpending": #AAF,
      "cancelled": #CCC,
      "catching": #CCC,
      "abandonment": #FAD,
      "notinvoiced": #ddf, //#faa,
      "fullyinvoiced": #afa,
      "partiallyinvoiced": #dfd,
      "paymentpending": #fda, //#dbd,
      "0value": #ccc,
      "closing": #AFA,
  ),
  "purchaseorder": (
    "prepared": #DDF,
    "sent": #FFA,
    "received": #BFB,
    "cancelled": #CCC,
  ),
  "registration": (
    "registered": #BFB,
    "canceled": #FAA,
    "rescheduled": #AAF,
    "catch-up": #FDA,
  ),
  "resourcebooking": (
    "1-requested": #FDA,
    "2-confirmed": #AFA,
    "3-canceled": #CEC,
  ),
  "sessionreport": (
    "considered": #EDE,
    "completed": #FDA,
    "accountedfor": #DFA,
    "closed": #BFB,
    "cancelled": #DDD,
  ),
  "training": (
    "writing": #DDDDFF,
    "waiting": #FFFFAA,
    "cancelled": #FCC,
    "deprecated": #EDD,
  ),
  "transport": (
    "1-new": #EDE,
    "3-ongoing": #FDA,
    "7-close": #BFB,
  ),
  "advisorvideolink": (
    "past": #FAA,
    "future": #FFA,
    "present": #DFD,
    "actual": #AFA,
  ),
);

.trainingsession--empty {
  color: #888;
  font-style: italic;
}

/*
  Progress bar for Tasks completion
*/
.completion {
  float: right;
  width: 80%;
  height: 20px;
  color: #99D0FF;
  &::-moz-progress-bar { background: #99D0FF; }
  &::-webkit-progress-bar { background-color: #99D0FF !important; }
}

@each $entity, $states in $entity-states {
    @each $state, $color in $states {
        .#{$entity}-#{$state} {
            background-color: $color;
        }
    }
}

.block-container.entities-container tr {
  @each $entity, $states in $entity-states {
    @each $state, $color in $states {
      &.#{$entity}-#{$state} {
        background-color: $color;
      }
    }
  }

  &.company-unknown {
    background: $evenRow;
  }

  &.deferment-to-determine {
    background-color: #FFAAAA;
  }
  &.unconfirmed {
    font-style: italic;
    color: #999999;
  }
}
