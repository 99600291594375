.list-purchase-description{
  font-weight: bold;
  font-size: 1em;
  margin: 1em 0 2em ;
}

.list-purchase-content li {
  list-style-type: none;
}

.link-purchase{
  display: inline-block;
  font-size: 0.9em ;
  text-decoration: none;
  color: #808080;
  padding: 5px;
  height: auto;
}

.list-purchase-content p {
  margin:0;
}
.link-purchase:hover {
  background-color: rgba(99, 99, 85, 0.36);
  color: #ffffff;
  font-weight: bold;

}
