.bulk>form>#form>.form-group>label{
  display: none;
}
.bulk>form>#form>.form-group>div>div{
  display: flex;
  flex-direction: row;
}
.bulk>form>#form>.form-group>div>div>.form-group{
  display: flex;
  flex-direction: column;
}