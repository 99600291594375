.entitySearch{
  position: absolute;
  z-index: 1;
  width: calc(100% - 30px);

  background-color: #555;
  list-style: none;
  padding: 0;

  li{
    padding: 2px;

    a{
      display: block;
      padding: 2px;
      color: white;

      &:hover{
        background-color: darkorange;
      }
    }
  }
}
