.tab-content{
  background-color: white;
}
.tab-content-title{
  font-size: larger;
  font-weight: bold;
  padding: 4px;
}
.tab-title>a{
  cursor: default;
  sup{
    color: red;
  }
}
acontent{
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.content-container{
  border: #337ab7 1px solid;
  padding: 4px;
  border-radius: 8px;
  margin: 4px;
  display: flex;
  flex-direction: column;
}
