.tree__item {
  margin-left: 2ch;
  border-left: 1px solid #ccc;
  padding: 0 1ch;

  &::before {
    content: '⊞ ─ 📁';
    margin-left: -3ch;
    margin-right: 1ch;
    color: #ccc;
  }
}
  .tree__item--closed > .tree__list {
    display: none;
  }
  .tree__item--open::before {
    content: '⊟ ─ 📁';
  }
.tree__link--active {
  text-decoration: underline;
}
.tree__list {
  list-style: none;
  padding: 0;
}
