.rating-1{
  color: red;
  text-align: center;
}
.rating-2{
  color: orange;
  text-align: center;
}
th{
  padding: 4px 16px 4px 16px;
  border-bottom: 2px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}
td{
  padding: 4px 16px 4px 16px;
  border-top: 1px solid #dee2e6;
}
#feedBackAnalysis-dateForm{
    max-width: 60%;
    display: flex;
    flex-wrap: wrap;
  form>#form{
    display: flex;
    flex-wrap: wrap;
  }
}