.table > thead > tr > th.field {
  vertical-align: top;
}

.field-search__form {
  display: none;

  &.active {
    display: block;
  }

  label {
    display: none;
  }

  .checkbox input[type="checkbox"] {
    margin-left: -20px;
    margin-top: -2px;
  }

  [class^="col-sm-"] {
    padding: 0;
    width: auto;
  }

  [class^="col-sm-"] ~ [class^="col-sm-"] .form-control {
    border-left: none;
  }

  .form-group .input-group-addon {
    border-bottom-left-radius: 0;
    width: 50%;
  }

  .form-group ~ .form-group .input-group-addon {
    border-top-left-radius: 0;
    border-bottom-left-radius: 4px;
  }

}

.field-search__submit {
  width: auto;
}

.block-container.entities-container .daterange {
  border-top: none;
  background-color: rgba(255,255,255, 0);
}
