@import "partials/variables";

.login-page.block-container {
  width: 30em;
  margin-top: 5em;

}

h1 {
  margin-top: 0;
  text-align: center;
}

#login-form {
  .form-group {
    display: flex;

    label {
      flex: 0 0 50%;
    }

    .form-field {
      flex: 0 1 auto;
    }

    /* submit form section */
    &.validate {
      /* add something if necessary */
    }
  }
}

footer {
  font-size: .8em;
  ul {
    display: inline-block;
    list-style-type: none;
    padding: 0;
    li {
      display: inline-block;
      padding: 0 1ch;
      border-right: 1px solid $light-text;
    }
  }
  p {
    display: inline-block;
  }
}