.durationType{
  display: flex;
  >div{
    label[for$='_hour']{
      display: none;
    }
    div{
      padding: 0;
      &:after{
        display: none;
      }
    }
  }
  >.form-group:first-child{
    width: 67px;
    >div>input{
      border: none;
      border-left: 1px solid #ccc;
    }
  }
  >.form-group:nth-child(2){
    display: flex;
    width: 92px;
    &:hover{
      background-color: white;
    }
    background: white;
    >div>input{
      background-color: white;
      border: none;
      border-right: 1px solid #ccc;
    }
  }
}