.chart__wrapper {
  overflow-x: auto;
}

.chart {
  font: 12px monospace;
}

.chart__grid {
  fill: #ddd;
  fill-opacity: .5;
}

.chart__block, .chart__text {
  cursor: pointer;
}

.chart__bar:hover .chart__block {
  filter: url(#dropshadow);
}

.chart__cursor {
  fill: #d33;
}
