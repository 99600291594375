@import "variables";

$normal-color: #337ab7;
$hover-color: #23527c;

.local-actions {
  padding: 0.2em 1em;
  background-color: $main-layout-color;
  position: sticky;
  top: 0;
  box-shadow: 0 .5em .5em -.5em black;

  ul {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 0;
    margin: 5px 0;
  }
  li {
    margin: 2px;
  }
  a,.link {
    display: block;
    padding: 0 1ch;
    text-decoration: none;
    border: 1px solid $normal-color;
    border-radius: 4px;
    &:hover,
    &:focus{
      border-color: $hover-color;
    }

    &.special {
      background-color: $normal-color;
      color: #fff;
      &:hover,
      &:focus{
        background-color: $hover-color;
      }
    }
  }
  .link{
      background: inherit;
      color: $normal-color;
    &:hover,
    &:focus{
        color: $hover-color;
    }
  }
}
