$body-color: #000;
$main-layout-color: #FFF;
$aside-layout-color: #E0E0E0;
$light-text: #858d93;
$commit-color: darken($aside-layout-color, 20%);
$link-color: #21536a;
$highlight-background: lighten($link-color, 90%);
$oddRow: #ffe;
$evenRow: #E0E0E0;
$font-family-base: "Roboto Condensed", Arial, Helvetica, sans-serif;
