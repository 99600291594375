@import './_variables.scss';

.nav-block {
  &:not(:first-child) {
    border-top: 1px solid $light-text;
  }
  h1 {
    font-size: .8em;
    color: $link-color;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
  }
  li {
    border-bottom: 1px solid $light-text;
  }

  a {
    font-size: .8em;
    color: $link-color;
    display: block;
  }
  a:hover {
    background-color: $highlight-background;
  }

  &.bookmark li {
    position: relative;
  }

  &.bookmark a+a {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.shortcuts {
  a {
    font-size: 1em;
    color: $link-color;
  }
  a:hover {
    background-color: $highlight-background;
  }
}