/**
 * Base example taken from [Mark](https://stackoverflow.com/users/16363/mark)
 */

.organizationChart {
  svg {
    display: block;
    margin: auto;
  }
  .node {
    cursor: pointer;
  }

  .node circle {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 1.5px;
  }

  .node text {
    font: 10px sans-serif;
  }

  .link {
    fill: none;
    stroke: #ccc;
    stroke-width: 1.5px;
  }
}
