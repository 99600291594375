$custom-white: #eeeeee;

[data-field="lastName"]{
  text-transform: uppercase;
}

.nav-pills {
  li.active {
    a {
      background-color: grey;
    }
  }
}

a{
  &:hover {
    text-decoration: none;
  }
}

/* CSS for the navBar */
.navbar{
  background-color: #212121;
  border: none;
}

/* CSS for the pagination */
.pagination{
  text-align: center;
}
.pagination-buttons{
  display: inline-flex;
  list-style-type: none;
  a {
    padding: 8px 16px;

    li:not(.current-page) {
      color: black;
    }
    &:not(.current-page):hover {
      background-color: #e2e1e0;
    }
  }

}
.current-page{
  background-color: #1976D2;
  color: $custom-white;
}
.current-page:hover{
  color: $custom-white;
}
a.current-page{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.after-sep::after, .before-sep::before{
  content: " | ";
  white-space: nowrap;
}

/* CSS global for the buttons */
.button{
  &:active {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    transition-delay: 0s;
    background-color: #2196F3;
  }
  &:hover{
    color: $custom-white;
  }
}

/* CSS for the search container */
.search-title{
  font-size: 32px;
}

/* CSS for the action bar */
.action-bar{
  padding-top: 30px;
  padding-bottom: 5px;
  background-color: #fff;
  margin: auto;
}

.navbar{
  margin-bottom: 0;
}

select[multiple]{
  resize: vertical;
}

/* When the burger menu appear we set is position on fixed to float above the body */
@media screen and (max-width: 768px){
  body>.navbar{
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 100;
  }
  body{
    padding-top: 50px;
  }
  .dropdown:hover:not(.open) .dropdown-menu{
    display: none;
  }
  .navbar-collapse.in{
    overflow-y: auto;
    height: 70vh;
  }
}

@import 'partials/entity_search_widget';
